import React, { useState, useEffect } from "react"
import "twin.macro"

import QuizForm from "./QuizForm"
import QuizResult from "./QuizResult"
import QuizSendResultsForm from "./QuizSendResultsForm"
import QuizThankYou from "./QuizThankYou"
import { scrollTo, isBrowser } from "@components/utils"

const Quiz = ({ quizData }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [submittedData, setSubmittedData] = useState(null)

  const goToPage = goToIndex => {
    setCurrentPage(goToIndex)
  }

  useEffect(() => {
    // Screenshots
    if (isBrowser) {
      const isEDMode = window.location.href.indexOf("ed=1") > -1
      if (isEDMode) {
        for (let i = 0; i <= 7; i++) {
          if (window.location.search.indexOf(`page${i}`) > -1) {
            setCurrentPage(i)
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    if (currentPage === 7) {
      const scrollToTopOfQuizEl = document.querySelector("#quiz")
      scrollTo(scrollToTopOfQuizEl)
    }
  }, [currentPage])

  return (
    <div tw="bg-lilac min-h-[396px] px-5 py-8 rounded-tl-[40px] rounded-tr-[16px] rounded-br-[40px] rounded-bl-[16px] lg:(min-h-[321px] p-14 rounded-tl-[72px] rounded-tr-[32px] rounded-br-[72px] rounded-bl-[32px])">
      {currentPage < 5 ? (
        <div tw="relative flex flex-col overflow-hidden">
          <QuizForm
            quizData={quizData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSubmittedData={setSubmittedData}
            submittedData={submittedData}
            goToPage={goToPage}
          />
        </div>
      ) : currentPage === 5 ? (
        <QuizResult
          quizData={quizData}
          submittedData={submittedData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          goToPage={goToPage}
        />
      ) : currentPage === 6 ? (
        <QuizSendResultsForm
          quizData={quizData}
          submittedData={submittedData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          goToPage={goToPage}
        />
      ) : (
        <QuizThankYou />
      )}
    </div>
  )
}

export default Quiz
