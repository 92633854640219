import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

// Components
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"
import Anchor from "@components/Anchor"
import ReactPlayer from "react-player"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useWindowSize } from "@components/utils"

// Assets
import pause from "@images/icons/orphan-hero-pause.svg"
import play from "@images/icons/orphan-hero-play.svg"

const VideoHero = ({
  video,
  children,
  eyebrow,
  jumpLinks,
  cta,
  footnotes,
  poster,
}) => {
  const [videoIsPlaying, setVideoIsPlaying] = useState(true)
  const [shouldAutoPlay, setShouldAutoPlay] = useState(true)
  const [hasWindow, setHasWindow] = useState()
  const { width } = useWindowSize()

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true)
      // Screenshots
      const isEDMode = window.location.href.indexOf("ed=1") > -1
      if (isEDMode) {
        setShouldAutoPlay(false)
      }
    }
  })

  const PlayIcon = () => {
    return <img src={play} tw="w-11 h-11" />
  }

  const PauseIcon = () => {
    return <img src={pause} tw="w-11 h-11" />
  }

  return (
    <section
      css={[
        tw`relative grid grid-cols-4 min-h-[360px] overflow-hidden pt-[35px] rounded-br-[52px] lg:(rounded-bl-[32px] rounded-br-[72px]) xl:(pt-5 min-h-[400px])`,
      ]}
    >
      {/* Heading copy */}
      <div
        css={[
          tw`pb-5 pt-4 [grid-column: 1/span 4] flex items-end md:(pt-14) lg:(pt-20) xl:(col-span-full pt-[80px] pb-6 items-center)`,
        ]}
      >
        <Container grid>
          <div
            css={[tw`col-span-full pr-10 lg:(col-span-5 max-w-[1050px] pr-12)`]}
          >
            <div>
              {eyebrow && (
                <p
                  css={[
                    tw`text-2xl uppercase text-white font-extrabold leading-[130%] [letter-spacing: 0.05em] mb-3 md:([grid-column: 1/span 3]) xl:(text-2xl)`,
                  ]}
                >
                  {eyebrow}
                </p>
              )}
              <div>{children}</div>
            </div>
            {footnotes && (
              <p tw="text-white font-medium mt-2 mb-2.5 xl:(mt-3.5 mb-4)">
                {footnotes}
              </p>
            )}
            {cta && (
              <div tw="mt-7 text-center lg:(mt-3.5 text-left)">
                <PrimaryCta
                  url={cta.link}
                  isExternal={cta.isExternal}
                  noInterstitial={cta.noInterstitial}
                  width={tw`md:max-w-[fit-content]`}
                >
                  {cta.text}
                </PrimaryCta>
              </div>
            )}
            {jumpLinks && (
              <section tw="text-white text-sm lg:(text-xl)">
                <hr tw="border-white my-6 w-full lg:(max-w-[540px]) xl:(max-w-[747px])" />
                <div tw="lg:flex xl:block">
                  <strong tw="font-black lg:(font-bold)">Jump to:</strong>
                  <ul tw="pt-5 md:(flex flex-col) lg:pt-0 xl:(inline-block)">
                    {jumpLinks.map((link, index) => {
                      return (
                        <li
                          tw="mb-5 last:(mb-0) lg:(ml-10 inline-block mb-4) xl:mb-0"
                          key={index}
                        >
                          {link.external ? (
                            <Anchor link={link.link} colour="white" newTab>
                              {link.text}
                            </Anchor>
                          ) : (
                            <Anchor link={link.link} colour="white">
                              {link.text}
                            </Anchor>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </section>
            )}
          </div>
        </Container>
      </div>

      {/* Hero image */}
      <div
        className="hero-video"
        tw="w-full h-full absolute top-0 right-0 bg-black"
      >
        <button
          onClick={() => setVideoIsPlaying(!videoIsPlaying)}
          css={[
            tw`absolute z-[1] top-[10px] right-[5px]`,
            tw`focus-visible:absolute`,
          ]}
        >
          {videoIsPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
        {hasWindow && (
          <ReactPlayer
            playsinline
            controls={false}
            muted
            loop
            playing={shouldAutoPlay && videoIsPlaying}
            width="100%"
            height="100%"
            css={[
              tw`absolute right-0 bottom-0 !h-auto !w-full [video]:(h-full max-w-none w-auto) md:(absolute right-0 bottom-0 !w-auto !h-[640px] [video]:(!h-full !max-w-none !w-auto !h-[640px]))`,
              tw`after:md:(!h-[640px] w-[150px] absolute left-0 top-0 bg-gradient-to-r from-black to-[rgba(0, 0, 0, 0)])`,
              video.mobile?.additionalStyles && video.mobile?.additionalStyles,
              video.desktop?.additionalStyles &&
                video.desktop?.additionalStyles,
            ]}
            url={width < 768 ? video.mobile.src : video.desktop.src}
            config={{
              file: {
                attributes: {
                  poster:
                    (width < 768
                      ? poster.mobile.srcPath
                      : poster.desktop.srcPath) || null,
                },
              },
            }}
          />
        )}
      </div>

      {/* Poster */}
      {poster && !video && (
        <>
          <GatsbyImage
            image={getImage(poster.desktop.src)}
            alt=""
            tw="hidden md:block absolute top-0 w-full h-full"
            objectPosition={poster.desktop.position}
          />
          <GatsbyImage
            image={getImage(poster.mobile.src)}
            alt=""
            tw="md:hidden absolute top-0 w-full h-full"
            objectPosition={poster.mobile.position}
          />
        </>
      )}
    </section>
  )
}

export default VideoHero

VideoHero.propTypes = {
  eyebrow: PropTypes.node,
  imageData: PropTypes.object, // needed when using GatsbyImage
  jumplinks: PropTypes.array,
  cta: PropTypes.object,
  bgImgSm: PropTypes.bool,
  gradient: PropTypes.string,
}
