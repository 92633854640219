import React from "react"
import tw from "twin.macro"

import { groupDashedFocus } from "@styles/ui"
import Anchor from "@components/Anchor"

import checkIcon from "@images/icons/quiz-check.svg"

const QuizResult = ({
  quizData,
  setCurrentPage,
  currentPage,
  submittedData,
  goToPage,
}) => {
  const buttonStyle = [
    tw`bg-cta-red text-base text-center inline-flex justify-center items-center uppercase font-extrabold text-white rounded-full py-4 px-8 transition-main cursor-pointer relative z-10 w-full min-h-[52px] focus-visible:outline-0 md:max-w-[350px] md:hover:(bg-merlin)`,
  ]

  return (
    <>
      <div tw="inline-block bg-white text-studio px-4 py-2 text-2xl font-extrabold uppercase rounded-2xl mb-8 md:(px-6 py-4)">
        Results
      </div>
      <p tw="font-bold text-5xl leading-10 font-ffcocon text-studio md:(text-left)">
        All done!
      </p>
      <p tw="mt-2 md:(text-left)">
        It&apos;s ready to be emailed! Bring it with you next time you see your
        doctor to talk about managing your Idiopathic Hypersomnia symptoms and
        if XYWAV could be right for you.
      </p>

      <div tw="w-full flex flex-col md:(flex-row justify-start)">
        <button
          className="group email-btn"
          css={[buttonStyle, tw`my-5 w-full md:w-[260px]`]}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <div
            css={[tw`absolute w-full h-full top-0 left-0`, groupDashedFocus]}
          />
          Email My Results
        </button>
      </div>

      <p tw="font-bold md:text-left">Here&apos;s your personalized summary:</p>

      <div tw="md:(flex flex-col)">
        {quizData.map((item, index) => {
          return (
            <div key={index}>
              <h3 tw="font-bold text-2xl font-ffcocon text-studio mb-3 mt-5">
                {item.resultHeading}
              </h3>
              {!submittedData[item.name].length && (
                <p tw="font-bold mb-3">
                  None of the following topics were of interest to you:
                </p>
              )}
              <ul tw="text-sm md:text-xl">
                {item.options.map((option, optionIndex) => {
                  const isUserSelection =
                    submittedData[item.name] &&
                    submittedData[item.name].filter(
                      item => item === option.value
                    )[0]

                  return (
                    <li
                      css={[
                        tw`mb-3 font-light relative ml-7`,
                        isUserSelection && tw`font-bold`,
                      ]}
                      key={optionIndex}
                    >
                      {isUserSelection && (
                        <img
                          src={checkIcon}
                          alt=""
                          tw="w-[21px] h-[16px] absolute -ml-7"
                        />
                      )}
                      {option.label || option.value}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
        <div tw="w-full flex flex-col items-start mt-6 md:mt-6">
          <button
            className="group back-btn"
            css={[
              buttonStyle,
              tw`!bg-transparent text-merlin border-2 border-coral-red w-[150px] md:(!bg-transparent hover:(text-white !bg-coral-red !border-coral-red))`,
            ]}
            onClick={() => goToPage(currentPage - 1)}
            type="button"
          >
            <div
              css={[tw`absolute w-full h-full top-0 left-0`, groupDashedFocus]}
            />
            Back
          </button>
          <p tw="text-xl mt-[30px] md:mt-[34px]">
            Prefer to fill out the quiz by hand?{" "}
            <Anchor newTab link="/pdf/50956_DDG_PDF_L07.pdf">
              Download a blank version
            </Anchor>
            .
          </p>
        </div>
      </div>
    </>
  )
}

export default QuizResult
