import React from "react"
import "twin.macro"

import nightIcon from "@images/icons/quiz-night-icon.svg"
import sunriseIcon from "@images/icons/quiz-sunrise-icon.svg"
import daytimeIcon from "@images/icons/quiz-daytime-icon.svg"

const quizData = [
  {
    name: "letsTalkNightTimeSleep",
    question: (
      <>
        Let&apos;s talk about how much you sleep (including both at night and
        any naps):
      </>
    ),
    instruction: "Select at least one option.*",
    options: [
      {
        label: (
          <span>
            A typical amount (around{" "}
            <span tw="whitespace-nowrap">7-8&nbsp;hours</span> each day).
          </span>
        ),
        value: "A typical amount (around 7-8 hours each day).",
      },
      {
        label: (
          <span>
            More than a typical amount (more than{" "}
            <span tw="whitespace-nowrap">8 hours</span> each day).
          </span>
        ),
        value: "More than a typical amount (more than 8 hours each day).",
      },
      {
        label: (
          <span>
            Less than a typical amount (less than{" "}
            <span tw="whitespace-nowrap">7 hours</span> each day).
          </span>
        ),
        value: "Less than a typical amount (less than 7 hours each day).",
      },
    ],
    isRequired: true,
    error: "You must make a selection to continue.",
    icon: [
      {
        image: nightIcon,
        width: "85px",
        height: "86px",
      },
    ],
    resultHeading: "How much I sleep (including both at night and any naps):",
  },
  {
    name: "letsTalkWakingUp",
    question: "Let's talk about waking up:",
    instruction: "Select all that apply (optional).",
    options: [
      {
        value: "I sleep through multiple alarms.",
      },
      {
        value: "I often need someone else's help to get out of bed.",
      },
      {
        value: "I feel groggy or confused as I try to wake up.",
      },
      {
        value: "I feel disoriented, irritable, or even clumsy.",
      },
      {
        value: "It can take up to a few hours before I can get going.",
      },
    ],
    icon: [
      {
        image: sunriseIcon,
        width: "173px",
        height: "111px",
      },
    ],
    resultHeading: "What it's like to wake up in the morning:",
  },
  {
    name: "letsTalkWhenYourAwake",
    question: "Let's talk about your symptoms during the day:",
    instruction: "Select all that apply (optional).",
    options: [
      {
        value: "I feel sleepy despite getting plenty of sleep.",
      },
      {
        value: "Any time I wake up, I feel exhausted.",
      },
      {
        label: (
          <span tw="md:max-w-[500px]">
            I find it hard to concentrate and remember things because of my
            sleepiness.
          </span>
        ),
        value:
          "I find it hard to concentrate and remember things because of my sleepiness.",
      },
    ],
    icon: [
      {
        image: daytimeIcon,
        width: "106px",
        height: "107px",
      },
    ],
    resultHeading: "My symptoms during the day:",
  },
  {
    name: "letsTalkAboutXywav",
    question: "Let's talk about XYWAV:",
    instruction: (
      <>
        Check off any points you&apos;d like to have a conversation with your
        doctor&nbsp;about. Select all that apply (optional).
      </>
    ),
    options: [
      {
        value:
          "I'd like to learn about XYWAV, the first and only FDA-approved treatment for Idiopathic Hypersomnia.",
      },
      {
        value:
          "I'm interested in hearing more about the multiple symptoms of Idiopathic Hypersomnia that XYWAV can help treat, including excessive daytime sleepiness, sleep inertia, long sleep time, and cognitive impairment.",
      },
      {
        label: (
          <span>
            I&apos;d like to know about the twice- or{" "}
            <span tw="whitespace-nowrap">once-nightly</span> dosing options for
            XYWAV.
          </span>
        ),
        value:
          "I'd like to know about the twice- or once-nightly dosing options for XYWAV.",
      },
    ],
    icon: null,
    resultHeading: "At my next doctor's visit I want to learn about:",
  },
  {
    name: "letsTalkAboutAnyQuestion",
    question: "Let's talk about any additional questions you may have:",
    instruction: "Select all that apply (optional).",
    options: [
      {
        value: "What are the possible side effects of XYWAV?",
      },
      {
        value: "How long could it take for XYWAV to start working for me?",
      },
      {
        value:
          "What did the XYWAV study of adults with Idiopathic Hypersomnia show?",
      },
      {
        value:
          "What financial or patient support is available if my doctor and I find that XYWAV is appropriate for me?",
      },
    ],
    icon: null,
    resultHeading:
      "When I'm talking with my doctor, here's what I want to ask:",
  },
]

export default quizData
